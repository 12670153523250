import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

import Layout from "@layout"

import Image from "@components/image"
import SVG from "@components/svg"
import Button from "@components/button"
import SocialSharing from "@components/social-sharing"

import * as styles from "./styles.module.scss"

class ResourceDetail extends React.Component {
  // data alias
  page = this.props.data.datoCmsResourceTruckVan
  shareUrl = this.props.location.href

  render() {
    const bodyContent =
      this.page.bodyNode && this.page.bodyNode.childMarkdownRemark
        ? this.page.bodyNode.childMarkdownRemark.html
        : this.page.body

    const videoContent =
      this.page.videoEmbededCodeNode && this.page.videoEmbededCodeNode.childMarkdownRemark
        ? this.page.videoEmbededCodeNode.childMarkdownRemark.html
        : this.page.videoEmbededCode

    return (
      <>
        <Helmet>
          <html lang="en" />
        </Helmet>

        <Layout
          seoMetaTags={this.page.seoMetaTags}
          menu={this.props.data.menu}
          footer={this.props.data.footer}
        >
          <div className={styles.resourceDetail}>
            <div className={styles.date}>{this.page.meta.publishedAt}</div>
            <h1>{this.page.title}</h1>
            <Image
              className={styles.image}
              src={{
                childImageSharp: {
                  gatsbyImageData: {
                    ...this.page.image.gatsbyImageData
                  }
                }
              }}
            />

            <div className={styles.shareAndCta}>
              {this.page.downloadCtaButtonLabel && this.page.downloadCtaLink && (
                <Button theme="default" to={this.page.downloadCtaLink} className={styles.button}>
                  <SVG name="download" />
                  {this.page.downloadCtaButtonLabel}
                </Button>
              )}
              <div className={styles.socials}>
                <SocialSharing
                  shareUrl={this.shareUrl}
                  label="Share"
                  title={this.page.title}
                  excerpt={this.page.description}
                />
              </div>
            </div>

            <div
              className={styles.body}
              dangerouslySetInnerHTML={{
                __html: bodyContent
              }}
            ></div>

            {videoContent && (
              <div
                className={styles.iframe_wrapper}
                dangerouslySetInnerHTML={{ __html: videoContent }}
              ></div>
            )}
            <SocialSharing
              shareUrl={this.shareUrl}
              label="Share"
              title={this.page.title}
              excerpt={this.page.description}
            />
          </div>
        </Layout>
      </>
    )
  }
}

export default ResourceDetail

export const query = graphql`
  query ResourceDetailTruckVanQuery($permalink: String!) {
    ...MegaMenu
    ...Footer

    datoCmsResourceTruckVan(permalink: { eq: $permalink }) {
      permalink
      title
      description
      seoMetaTags {
        tags
      }
      descriptionNode {
        childMarkdownRemark {
          html
        }
      }
      body
      bodyNode {
        childMarkdownRemark {
          html
        }
      }
      meta {
        publishedAt(formatString: "MMMM DD, YYYY")
      }
      downloadCtaButtonLabel
      downloadCtaLink
      image {
        gatsbyImageData
      }
      videoEmbededCodeNode {
        childMarkdownRemark {
          html
        }
      }
      videoEmbededCode
    }
  }
`
