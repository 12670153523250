import React, { useState } from "react"
import PropTypes from "prop-types"

import { CopyToClipboard } from "react-copy-to-clipboard/src/Component"
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from "react-share"

import SVG from "@components/svg"
import * as styles from "./styles.module.scss"

const SocialSharing = ({ title, excerpt, label, shareUrl, showCopy }) => {
  const [showCopiedSuccess, setShowCopySuccess] = useState(false)

  const notifyCopied = () => {
    setShowCopySuccess(true)

    setTimeout(() => {
      setShowCopySuccess(false)
    }, 3000)
  }

  return (
    <div className={styles.socialSharing}>
      {label && <p className={styles.label}>{label}</p>}

      <ul>
        <li style={{ display: showCopy ? "inline-block" : "none" }}>
          {showCopiedSuccess && <div className={styles.copied}>Link copied to clipboard</div>}
          <CopyToClipboard text={shareUrl} onCopy={() => notifyCopied()}>
            <span>
              <SVG name="link" />
            </span>
          </CopyToClipboard>
        </li>
        <li>
          <FacebookShareButton url={shareUrl} quote={title && excerpt && `${title} - ${excerpt}`}>
            <span className="icon">
              <SVG name="facebook" />
            </span>
          </FacebookShareButton>
        </li>
        <li>
          <LinkedinShareButton url={shareUrl}>
            <span className="icon">
              <SVG name="linkedin" />
            </span>
          </LinkedinShareButton>
        </li>
        <li>
          <TwitterShareButton title={title && excerpt && `${title} - ${excerpt}`} url={shareUrl}>
            <span className="icon">
              <SVG name="twitter" />
            </span>
          </TwitterShareButton>
        </li>
      </ul>
    </div>
  )
}

SocialSharing.propTypes = {
  excerpt: PropTypes.string,
  label: PropTypes.string,
  shareUrl: PropTypes.string,
  showCopy: PropTypes.bool,
  title: PropTypes.string
}

SocialSharing.defaultProps = {
  showCopy: true
}

export default SocialSharing
